<template>
  <div class="header-container">
    <h1>{{content.page.title}}</h1>
  </div>
  <div class="container">
    <section v-html="content.page.mainContent">

    </section>
    <h2 v-if="qualifier===null && 1==2">{{content.page.result1Title}}</h2>
    <p v-if="qualifier===null && 1==2">{{content.page.result1Txt}}</p>
    <div v-else class="table-responsive" v-if="qualifier===null && 1==2">
        <table class="table table-hover">
            <thead>
            <tr>
                <th>#</th>
                <th>{{content.page.col1}}</th>
                <th>{{content.page.col2}}</th>
                <th>{{content.page.col5}}</th>
                <th>{{content.page.col6}}</th>
                <th>{{content.page.col7}}</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(data,index) in final" :key="index">
                <tr>
                    <td><div>{{data.rank}}</div></td>
                    <td>{{data.teamName}}</td>
                    <td>{{data.university}}</td>
                    <td>{{data.score1}}</td>
                    <td>{{data.score2}}</td>
                    <td>{{data.sumScore}}</td>
                </tr>
            </template>
            </tbody>
        </table>
    </div>
    <h2 v-if="1==2">{{content.page.result2Title}}</h2>
    <p v-if="1==2">{{content.page.result2Txt}}</p>
    <div class="table-responsive" v-if="qualifier===null && 1==2">
      <table class="table table-hover">
        <thead>
        <tr>
          <th>#</th>
          <th>{{content.page.col1}}</th>
          <th>{{content.page.col2}}</th>
          <th>{{content.page.col3}}</th>
          <th>{{content.page.col4}}</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(data,index) in qualifier" :key="index">
            <tr>
                <td><div>{{data.rank}}</div></td>
                <td>{{data.teamName}}</td>
                <td>{{data.university}}</td>
                <td>{{data.poolCode}}</td>
                <td>{{data.score}}</td>
            </tr>
            <tr v-if="data.rank==10">
                <td colspan="5">
                    {{content.page.line}}
                </td>
            </tr>
        </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

export default{
  name:'SubPage',
  props:['initData','contents','pageId'],
  data(){

    return{
        qualifier : this.initData.init.results.qualifier,
        final : this.initData.init.results.final,
    }
  },
  computed:{
    language(){
      return this.initData.init.language
    },
    content(){
      let contents = this.contents[this.pageId]

      this.initData.init.head.pageId = this.pageId
      this.initData.init.head.title = contents.head.title
      this.initData.init.head.keywords = contents.head.keywords
      this.initData.init.head.description = contents.head.description

      return contents
    }
  }
}
</script>
<style scoped>
div {
  margin: 0 auto;
  padding: 0;
}

img{
  width: 100%;
  height: 100%;
}

.container {
  max-width: 1064px;
  margin-top: 5rem;
  margin-bottom: 7rem;
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 9rem;
  background-image: linear-gradient(to right, rgba(119, 132, 16, .8), rgba(119, 132, 16, .8)), url("@/assets/img/background.png");
  mix-blend-mode: normal;
}

.header-container h1 {
  color: var(--white);
  font-size: 3rem;
  font-weight: 300;
}

h2 {
  font-style: normal;
  font-weight: 300;
  font-size: 2.5rem;
  line-height: 3rem;
}

h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.875rem;
  margin-bottom: 2.75rem;
}

table thead th{
  text-transform: uppercase;
}

.table-hover > tbody > tr:hover > * {
  color: var(--white);
  background-color: var(--class-green);
}

@media (max-width: 768px){
  .container {
    padding: 0 1rem;
  }
}
</style>